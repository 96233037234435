export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"My amazing site."},{"charset":"utf-16"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"كولوي"},{"hid":"keywords","name":"keywords","content":"استشارات اون لاين,خدمة استشارات اونلاين,استشارات"},{"hid":"author","name":"author","content":"خدمة استشارات اونلاين"},{"hid":"og-title","property":"og:title","content":"كولوي"},{"hid":"og-description","property":"og:description","content":"كولوي"},{"hid":"og-image","property":"og:image","content":"~/assets/images/header/hero.jpg"},{"hid":"og-type","property":"og:type","content":"website"},{"property":"og:site_name","content":"كولوي"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/callwe_fav.png"},{"rel":"manifest","type":"json","href":"manifest.json"}],"style":[],"script":[{"hid":"stripe","src":"https://goSellJSLib.b-cdn.net/v2.0.0/js/gosell.js","defer":true},{"hid":"respondio__widget","id":"respondio__widget","src":"https://cdn.respond.io/webchat/widget/widget.js?cId=8fe1495b6b513db05c1849fe7e1efbf","defer":true}],"noscript":[],"charset":"utf-16","viewport":"width=500, initial-scale=1","title":"CALL WE","titleTemplate":"%s - كولوي","htmlAttrs":{"lang":"ar"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null